import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import "./style.scss";
import $ from 'jquery';
import 'slick-carousel';

$(document).ready(function () {
    $(document.body).fadeIn(100);
    $('.slider').slick({
        autoplay: true,
        dots: true,
        arrows: false
    });
    $('.slider-partners').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true
    });
    $('.burger-menu').click(function () {
        $(this).toggleClass('open');
        $('.desktop-menu ').toggleClass('show-mobile-dropdown');
    });
    $('.accordion-header').click(function () {
        // Check if the clicked accordion item is already open
        var isOpen = $(this).hasClass('active');        // Hide all accordion content and remove 'active' class from headers
        $('.accordion-content').slideUp();
        $('.accordion-header').removeClass('active');        // Toggle the clicked accordion item if it wasn't open
        if (!isOpen) {
            $(this).next('.accordion-content').slideDown();
            $(this).addClass('active');
        }
    });

    const testimonials = [
        { front: true, author: "P&G ALUMNI NETWORK", text: "Ed Tazzia is Global Chairman of the 35,000 member P&G Alumni Network. Several other Sycamore  principals and associates also are active members."},
        { front: true, author: "VP, AkzoNobel", text: "They have personal relationships with proven talent around the world, which they leverage to locate a strong set of candidates for the job."},
        { front: true, author: "Former VP of Marketing, eBay", text: "Sycamore’s relationship with the P&G Alumni Network is extremely valuable and unique. They can reach into every major geography, industry and function to identify the best talent."},
        { front: false, author: "Executive Chairman of Dymatize and President of the Phoenix Suns", text: "Sycamore was retained to find our senior marketing executive at Dymatize Nutrition, but over the next eight months they helped us with operations, R&D and marketing. Four additional roles because they understood our business and culture."},
        { front: false, author: "Former President Marketing and Innovation, Diageo", text: "You’ve heard of teams having a coach on the field? This is like having a General Manager leading your search."},
        { front: false, author: "VP, AkzoNobel", text: "They have a unique ability to assess not only candidates’ achievements, but also the underlying skills that contributed to those achievements. This has allowed us to select employees that match our culture and value system, and who are very effective in their role here."},
        { front: false, author: "Senior Private Equity Advisor", text: "A good brand promises the delivery of a benefit, a great brand consistently delivers a benefit of genuine importance to the customer. Sycamore is a great brand, when your key assets walk in and out of the building every day."},
        { front: false, author: "Partner, TA Associates Private Equity", text: "You don’t just get the usual suspects. They show you remarkable talent you never would have seen from other firms."},
        { front: false, author: "VP Marketing, Lifestyle Lift", text: "They think about your business as an integrated team, not as a one-off hire."},
        { front: false, author: "Former VP, Marketing Oreck", text: "They have pulled my bacon out of the fire many times."},
    ]

    const $testimonials = $('.testimonials-pane')
    if ($testimonials.length > 0) {
        testimonials
            .filter(t => window.location.pathname !== '/' || t.front)
            .sort(() => Math.random() - 0.5)
            .forEach(t => $testimonials.append(`<li><p>${t.text}</p><p>${t.author}</p></li>`))
        $testimonials.slick({ infinite: true, autoplay: true, slidesToScroll: 1, arrows: false })
    }


});